<template>
  <v-main>
    <AppBar title="Completed Orders"></AppBar>
    <v-container>

      <Loading v-model="isLoading"></Loading>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            ref="search"
            label="Search"
            solo
            hide-details="auto"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-data-table
            v-model="selectedOrders"
            class="elevation-2"
            :headers="headers"
            :items="ordersList"
            item-key="id"
            :loading="$apollo.loading"
            loading-text="Loading... Please wait"
            :search="search"
            sort-by="pickedup_at"
            :sort-desc="true"
            mobile-breakpoint="0"
            >


            <template v-slot:[`item.pickup_first_name`]="{ item }">
              {{item.pickup_first_name}} {{item.pickup_last_name}}
            </template>

            <template v-slot:[`item.note`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{item.note | truncate(20, '...')}}</span>
                </template>
                <span>{{item.note}}</span>
              </v-tooltip>
            </template>

            <!--
            <template v-slot:[`item.id`]="{ item }">


              <v-btn @click="editOrder(item)" :id="item.id" small color="primary" icon="" tile>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>

              <v-btn :loading="item == isDeletingItem" @click="deleteOrder(item)" :id="item.id" small color="error" icon="" tile>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            -->

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import AppBar from '../components/AppBar'
import Loading from '../components/Loading'
import {COMPLETED_ORDERS_QUERY} from '../graphql/queries'
import {DELETE_ORDER_MUTATION, DELETE_ORDERS_MUTATION, UPDATE_ORDER} from '../graphql/mutations'
export default {
  name: 'OpenOrders',

  components: {
    AppBar,
    Loading
  },

  data: () => ({
    search: null,
    headers: [
      {text: 'Pickup Date', value: 'pickedup_at'},
      {text: 'Order', value: 'name'},
      {text: 'Recipient', value: 'pickup_first_name'},
      {text: 'Note', value: 'pickup_note'},
      //{text: '', value: 'id'}
    ],
    orders: [],
    isDeletingItem: null,
    selectedOrders: [],
    selectAll: true,
    isLoading: false,
    dialogEdit: false,
    editedIndex: -1,
    editedOrder: {
      first_name: null,
      last_name: null,
      email: null,
      note: null
    },
    rules: {
      orderName: [
        v => !!v || 'Order Number is required',
      ],
      customerFirstName: [
        v => !!v || 'Customer Name is required',
      ],
      email: [
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    },
  }),

  computed: {
    ordersList() {
      return this.orders.slice().sort((a,b) => {
        return a.created_at - b.created_at
      })
    }
  },

  apollo: {
    orders() {
      return {
        query: COMPLETED_ORDERS_QUERY,
        variables: {
          status: 'COMPLETED'
        }
      }
    }
  },

  watch:{
    selectedOrders() {
      if (this.selectedOrders.length == this.orders.length) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    }
  },

  methods: {
    deleteOrder(order)
    {
      this.isDeletingItem = order
      console.log(this.orders)
      let index = this.orders.indexOf(order)
      this.orders.splice(index, 1)
      this.$apollo.mutate({mutation: DELETE_ORDER_MUTATION, variables: {id: order.id}})
        .then((response) => {
          this.isDeletingItem = null
          this.$store.dispatch('READY_ORDERS_COUNT', this.$apolloProvider)
          //const order = response.data.createOrder
          //this.orders.push(order)
          //this.reset()
          console.log(response)
        })
        .catch((error) => {
          this.isDeletingItem = null
          console.error(error)
        })
    },
    deleteSelectedOrders() {
      this.isLoading = true
      var ids = this.selectedOrders.map((o) => {
        return o.id
      })
      this.$apollo.mutate({mutation: DELETE_ORDERS_MUTATION, variables: {ids}})
        .then(() => {
          this.isLoading = false
          this.$store.dispatch('READY_ORDERS_COUNT', this.$apolloProvider)
          this.selectedOrders = []
          this.$apollo.queries.orders.refetch()
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error)
        })
    },
    toggleSelect() {
      if (!this.selectAll) {
        this.selectedOrders = []
      } else {
        this.selectedOrders = this.orders
      }
      this.selectAll = !this.selectAll
    },
    editOrder(order) {
      console.log(order)
      this.editedIndex = this.orders.indexOf(order)
      this.editedOrder = Object.assign({}, order)
      this.dialogEdit = true
    },
    close () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedOrder = {
          first_name: null,
          last_name: null,
          email: null,
          note: null
        }
        this.editedIndex = -1
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.orders[this.editedIndex], this.editedOrder)
        const order = {
          id: this.editedOrder.id,
          first_name: this.editedOrder.first_name,
          last_name: this.editedOrder.last_name,
          email: this.editedOrder.email,
          note: this.editedOrder.note,
        }
        this.$apollo.mutate({mutation: UPDATE_ORDER, variables: {...order}})
      }
      this.close()
    },

    age(age) {
      if (age < 60) {
        return `${age} minutes`
      } else if (age >= 60 && age < 1440) {
        let hours = age / 60;
        hours = Math.round((hours + Number.EPSILON) * 100) / 100
        return `${hours} hours`
      } else if(age >= 1440) {
        let days = age / 1440
        days = Math.round((days + Number.EPSILON) * 100) / 100
        return `${days} days`
      }
    },

  }

}
</script>