<template>
  <v-main>
    <AppBar title="Ready for Pickup"></AppBar>
    <v-container>
      <Feedback ref="feedback"></Feedback>
      <Loading v-model="isLoading"></Loading>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            ref="search"
            label="Search"
            solo
            hide-details="auto"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" class="text-right">
          <!--<v-btn color="primary" @click.stop="toggleSelect()">{{selectAll ? 'SELECT ALL' : 'UNSELECT ALL'}}</v-btn>-->
          <v-btn :disabled="!selectedOrders.length" class="ml-2" color="warning" @click.stop="sendReminders()" >REMIND</v-btn>
          <!--<v-btn :disabled="!selectedOrders.length" class="ml-2" color="error" @click.stop="deleteSelectedOrders()">DELETE</v-btn>-->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-data-table
            v-model="selectedOrders"
            selectable-key="id"
            show-select
            class="elevation-2"
            :headers="headers"
            :items="ordersList"
            item-key="id"
            :loading="$apollo.loading"
            loading-text="Loading... Please wait"
            :search="search"
            mobile-breakpoint="0"
            >

            <template v-slot:top>
              <v-dialog v-model="dialogEdit" max-width="600px">
                <v-card>
                  <v-card-title class="headline">{{editedOrder.name}}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form v-model="editFormValid">
                      <v-row>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            ref="customer_first_name"
                            label="Customer First Name"
                            :rules="rules.customerFirstName"
                            v-model="editedOrder.first_name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="12">
                          <v-text-field
                            ref="customer_last_name"
                            label="Customer Last Name (Optional)"
                            placeholder=""
                            v-model="editedOrder.last_name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            ref="customer_email"
                            label="Email Address (Optional)"
                            v-model="editedOrder.email"
                            type="email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            ref="note"
                            label="Note (Optional)"
                            v-model="editedOrder.note"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!editFormValid"
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>                
                </v-card>
              </v-dialog>

            </template>

            <template v-slot:[`item.age`]="{ item }">
              {{age(item.age)}}
            </template>

            <template v-slot:[`item.note`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{item.note | truncate(20, '...')}}</span>
                </template>
                <span>{{item.note}}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.id`]="{ item }">


              <v-btn @click="editOrder(item)" :id="item.id" small color="primary" icon="" tile>
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <!--
              <v-btn :loading="item == isDeletingItem" @click="deleteOrder(item)" :id="item.id" small color="error" icon="" tile>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
              -->

            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import AppBar from '../components/AppBar'
import Loading from '../components/Loading'
import Feedback from '../components/Feedback'
import {ORDERS_QUERY} from '../graphql/queries'
import {CANCEL_ORDER_MUTATION, DELETE_ORDERS_MUTATION, UPDATE_ORDER, SEND_REMINDERS_MUTATION} from '../graphql/mutations'
export default {
  name: 'OpenOrders',

  components: {
    AppBar,
    Loading,
    Feedback
  },

  data: () => ({
    editFormValid: false,
    search: null,
    headers: [
      {text: 'Date', value: 'received_at'},
      {text: 'Order', value: 'name'},
      {text: 'Customer', value: 'customer_name'},
      {text: 'Email', value: 'email'},
      {text: 'Age', value: 'age'},
      {text: 'Note', value: 'note'},
      {text: '', value: 'id'}
    ],
    orders: [],
    isDeletingItem: null,
    selectedOrders: [],
    selectAll: true,
    isLoading: false,
    dialogEdit: false,
    editedIndex: -1,
    editedOrder: {
      first_name: null,
      last_name: null,
      email: null,
      note: null
    },
    rules: {
      orderName: [
        v => !!v || 'Order Number is required',
      ],
      customerFirstName: [
        v => !!v || 'Customer Name is required',
      ],
      email: [
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    },
  }),

  computed: {
    ordersList() {
      return this.orders.slice().sort((a,b) => {
        return a.created_at - b.created_at
      })
    }
  },

  apollo: {
    orders() {
      return {
        query: ORDERS_QUERY,
        variables: {
          status: 'READY'
        }
      }
    }
  },

  watch:{
    selectedOrders() {
      if (this.selectedOrders.length) {
        this.locknav()
      } else {
        this.unlocknav()
      }

      if (this.selectedOrders.length == this.orders.length) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    }
  },

  methods: {
    sendReminders() {
      this.isLoading = true
      const items = []
      for(let i=0;i<=this.selectedOrders.length-1;i++) {
        items.push(this.selectedOrders[i].id)
      }
      this.$apollo.mutate({
        mutation: SEND_REMINDERS_MUTATION,
        variables: {ids: items}
      })
        .then((response) => {
          this.unlocknav()
          this.isLoading = false
          const c = response.data.sendReminders
          let str = ''
          if (c > 1) {
            str = c + ' customers '
          } else {
            str = c + ' customer '
          }
          str += 'reminded!'
          this.$refs.feedback.success(str)
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    deleteOrder(order)
    {
      this.locknav()
      this.isDeletingItem = order
      console.log(this.orders)
      let index = this.orders.indexOf(order)
      this.orders.splice(index, 1)
      this.$apollo.mutate({mutation: CANCEL_ORDER_MUTATION, variables: {id: order.id}})
        .then((response) => {
          this.unlocknav()
          this.isDeletingItem = null
          this.$store.dispatch('READY_ORDERS_COUNT', this.$apolloProvider)
          //const order = response.data.createOrder
          //this.orders.push(order)
          //this.reset()
          console.log(response)
        })
        .catch((error) => {
          this.isDeletingItem = null
          console.error(error)
        })
    },
    deleteSelectedOrders() {
      this.locknav()
      this.isLoading = true
      var ids = this.selectedOrders.map((o) => {
        return o.id
      })
      this.$apollo.mutate({mutation: DELETE_ORDERS_MUTATION, variables: {ids}})
        .then(() => {
          this.unlocknav()
          this.isLoading = false
          this.$store.dispatch('READY_ORDERS_COUNT', this.$apolloProvider)
          this.selectedOrders = []
          this.$apollo.queries.orders.refetch()
        })
        .catch((error) => {
          this.isLoading = false
          console.error(error)
        })
    },
    toggleSelect() {
      if (!this.selectAll) {
        this.selectedOrders = []
      } else {
        this.selectedOrders = this.orders
      }
      this.selectAll = !this.selectAll
    },
    editOrder(order) {
      console.log(order)
      this.editedIndex = this.orders.indexOf(order)
      this.editedOrder = Object.assign({}, order)
      this.dialogEdit = true
      this.locknav()
    },
    close () {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedOrder = {
          first_name: null,
          last_name: null,
          email: null,
          note: null
        }
        this.editedIndex = -1
      })
      this.unlocknav()
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.orders[this.editedIndex], this.editedOrder)
        const order = {
          id: this.editedOrder.id,
          first_name: this.editedOrder.first_name,
          last_name: this.editedOrder.last_name,
          email: this.editedOrder.email,
          note: this.editedOrder.note,
        }
        this.$apollo.mutate({mutation: UPDATE_ORDER, variables: {...order}})
          .then(() => {
            this.$apollo.queries.orders.refetch()
          })
      }
      this.close()
    },

    age(age) {
      if (age < 60) {
        return `${age} minutes`
      } else if (age >= 60 && age < 1440) {
        let hours = age / 60;
        hours = Math.round((hours + Number.EPSILON) * 100) / 100
        return `${hours} hours`
      } else if(age >= 1440) {
        let days = age / 1440
        days = Math.round((days + Number.EPSILON) * 100) / 100
        return `${days} days`
      }
    },

  }

}
</script>