<template>
  <v-main>
    <AppBar title="Reporting"></AppBar>
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12" md="3" sm="12">
          <v-card>
            <v-card-title>Order on-hand</v-card-title>
            <v-card-text>{{ordersReportingByAccount.ordersOnHand}}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" sm="12">
          <v-card>
            <v-card-title>Average order age</v-card-title>
            <v-card-text>{{age(ordersReportingByAccount.avgAge)}}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="12">
          <v-card>
            <v-card-title>Order Age Distribution</v-card-title>
            <v-card-text>
              <div id="pieChart">
                <fusioncharts
                  :type="pieChart.type"
                  :dataFormat="pieChart.dataFormat"
                  :dataSource="ageDistributionDataSource"
                  width="100%"
                  labelDisplay="none"
                >
                </fusioncharts>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dateRange"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-card>
                <v-card-text>
              <v-text-field
                :loading="$apollo.queries.ordersReportsForDateByAccount.loading"
                v-model="dateRange"
                label="Select date interval"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
                </v-card-text>
              </v-card>
            </template>
            <v-date-picker
              v-model="dateRange"
              scrollable
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(dateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card>
            <v-card-title>Total Pickups</v-card-title>
            <v-card-text>{{ordersReportsForDateByAccount.totalPickups}}</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card>
            <v-card-title>Total Received</v-card-title>
            <v-card-text>{{ordersReportsForDateByAccount.totalOnHand}}</v-card-text>
            <!-- <v-card-text>{{ ordersReportingByAccount.ordersOnHand }}</v-card-text> -->
          </v-card>
        </v-col>
      </v-row>
      <!--<v-row v-if="totalDataSource.dataset[0].data.length">-->
      <v-row>
        <v-col cols="12" sm="12">
          <v-card>
            <v-card-text>
              <div id="lineChart">
                <fusioncharts
                  :type="lineChart.type"
                  :dataFormat="lineChart.dataFormat"
                  width="100%"
                  :dataSource="totalDataSource"
                >
                </fusioncharts>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
const currentDate = function() {
  let yourDate = new Date()
  const offset = yourDate.getTimezoneOffset()
  yourDate = new Date(yourDate.getTime() - (offset*60*1000))
  return yourDate.toISOString().split('T')[0]
}
import AppBar from '../components/AppBar.vue'
import gql from 'graphql-tag'
export default {
  components: {
    AppBar
  },

  data: () => ({
    ordersReportingByAccount: {
      ordersOnHand: 0,
      avgAge: 0,
      ageDistribution: [],
    },
    pieChart: {
      type: 'pie3d',
      dataFormat: 'json',
      renderAt: 'pieChart'
    },
    lineChart: {
      type: 'msline',
      dataFormat: 'json',
      renderAt: 'lineChart'
    },
    modal: false,
    dateRange: [currentDate(), currentDate()],
    ordersReportsForDateByAccount: {
      totalOnHand: 0,
      totalPickups: 0,
      onHandSeries: [],
      pickupsSeries: []
    },
    lineChartRef: null,
  }),

  computed: {
    tabs() {
      return [
        {title: 'Pickup', to: '/orders/pickup'},
        {title: 'Manage', to: '/orders/manage'},
        {title: 'Reporting', to: '/orders/reports'},
      ]
    },
    ageDistributionDataSource() {
      return {
        data: this.ordersReportingByAccount.ageDistribution,
        chart: {
          theme: "fusion",
          labelDisplay: 'none',
          showLabels: 0,
          plotToolText: "$dataValue Order(s)"
        }
      }
    },
    totalDataSource() {
      return {
        chart: {
          theme: "fusion",
          caption: "",
          subCaption: "",
          xAxisName: "Day"          
        },
        categories: [{
          category: this.ordersReportsForDateByAccount.onHandSeries
        }],
        dataset: [
          {
            seriesname: 'Total Received',
            renderAs: "line",
            data: this.ordersReportsForDateByAccount.onHandSeries
          },
          {
            seriesname: 'Total Pickup',
            renderAs: "line",
            data: this.ordersReportsForDateByAccount.pickupsSeries
          },
        ]
      }
    }
  },

  apollo: {
    ordersReportingByAccount() {
      return {
        query: gql`
          query ordersReportingByAccount {
            ordersReportingByAccount {
              timestamp
              ordersOnHand
              avgAge
              ageDistribution {
                label
                value
              }
            }
          }
        `,
      }
    },
    ordersReportsForDateByAccount: {
      query: gql`
        query ordersReportsForDateByAccount(
          $from: String
          $to: String
        ) {
          ordersReportsForDateByAccount(
            from: $from,
            to: $to
          ) {
            timestamp
            totalOnHand
            totalPickups
            onHandSeries {
              value
              label
            }
            pickupsSeries {
              value
            }
          }
        }
      `,
      variables() {
        return {
          from: this.dateRange[0],
          to: this.dateRange[1]
        }
      }
    }
  },

  watch: {
    dateRange(newVal) {
      if (newVal.length == 2) {
        this.$apollo.queries.ordersReportsForDateByAccount.refetch()
      }
    }
  },

  methods: {

    age(age) {
      if (age < 60) {
        return `${age} minutes`
      } else if (age >= 60 && age < 1440) {
        let hours = age / 60;
        hours = Math.round((hours + Number.EPSILON) * 100) / 100
        return `${hours} hours`
      } else if(age >= 1440) {
        let days = age / 1440
        days = Math.round((days + Number.EPSILON) * 100) / 100
        return `${days} days`
      }
    },

    // lineChartBeforeRender(event) {
    //   this.lineChartRef = event.sender;
    // },

  },

}
</script>