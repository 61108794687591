<template>
<v-main>
  <AppBar @onProcess="onProcess" :drawer="drawer" :buttons="buttons" title="Create Order">
      <template v-slot:title>
        <v-toolbar-title class="toolbar--shortcut" style="background:#FF1744;color:#FFFFFF">
          Press 'Process' to confirm the created orders.
        </v-toolbar-title>
      </template>

  </AppBar>
  <v-container>

    <Loading v-model="isLoading"></Loading>
    <Feedback ref="feedback"></Feedback> 


    <v-form ref="form" v-model="isValid">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="order_name"
            label="Order Number"
            solo
            hide-details="auto"
            :rules="rules.orderName"
            v-model="order.name"
            @keypress.enter="add()"
            validate-on-blur
            :autofocus="false"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="customer_first_name"
            label="Customer First Name"
            solo
            hide-details="auto"
            :rules="rules.customerFirstName"
            v-model="order.first_name"
            @keypress.enter="add()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            ref="customer_last_name"
            label="Customer Last Name"
            :rules="rules.customerLastName"
            solo
            hide-details="auto"
            v-model="order.last_name"
            @keypress.enter="add()"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            ref="customer_email"
            label="Email Address"
            solo
            hide-details="auto"
            v-model="order.email"
            @keypress.enter="add()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            ref="note"
            label="Note (Optional)"
            solo
            hide-details="auto"
            v-model="order.note"
            @keypress.enter="add()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" style="text-align:right">
          <v-btn @click="reset" depressed color="primary" outlined style="margin-right:16px">RESET</v-btn>
          <v-btn @click="add" :loading="isSaving" depressed color="primary" :disabled="!isValid">SAVE ENTRY</v-btn>
        </v-col>
      </v-row>

    </v-form>

    <v-row class="mt-20">
      <v-col cols="12" sm="12">
        <h3>Recently added</h3>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col cols="12" sm="12">
        <v-data-table
          class="elevation-2"
          :headers="headers"
          :items="orders"
          item-key="id"
          :loading="$apollo.loading"
          loading-text="Loading... Please wait"
          sort-by="created_at"
          :sort-desc="true"
          mobile-breakpoint="0"
          >

          <template v-slot:[`item.note`]="{item}">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >{{item.note | truncate(20, '...')}}</span>
              </template>
              <span>{{item.note}}</span>
            </v-tooltip>
          </template>


          <template v-slot:[`item.id`]="{ item }">
            <div class="text-center">
            <v-btn :loading="item == isDeletingItem" @click.stop="deleteOrder(item)" :id="item.id" small color="error" icon="" tile>
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
            </div>
          </template>

        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</v-main>
</template>
<script>
import AppBar from '../components/AppBar'
import Feedback from '../components/Feedback'
import Loading from '../components/Loading'
import {CREATE_ORDER_MUTATION, DELETE_ORDER_MUTATION, PROCESS_ADDED_ORDERS_MUTATION} from '../graphql/mutations'
import {ORDERS_QUERY} from '../graphql/queries'
export default {
  name: 'CreateOrder',

  props: ['drawer'],

  components: {
    AppBar,
    Feedback,
    Loading
  },

  data:() => ({
    isValid: false,
    isSaving: false,
    isDeletingItem: null,
    isLoading: false,
    order: {
      name: null,
      first_name: null,
      last_name: null,
      email: null,
      note: null
    },
    orders: [],
    rules: {
      orderName: [
        v => !!v || 'Order Number is required',
      ],
      customerFirstName: [
        v => !!v || 'Customer First Name is required',
      ],
      customerLastName: [
        v => !!v || 'Customer Last Name is required',
      ],
      email: [
        v => !!v || 'Email is required',
        v => !v ? true : (/.+@.+/.test(v) || 'Email must be valid'),        
      ]
    },
    headers: [
      {text: 'Order', value: 'name'},
      {text: 'Customer', value: 'customer_name'},
      {text: 'Email', value: 'email'},
      {text: 'Note', value: 'note'},
      {text: '', value: 'id'}
    ],
  }),

  computed: {
    buttons() {
      return [
        {label: 'PROCESS', click: 'onProcess', color: 'error', right: true, hidden: false, disabled: this.isLoading || !this.orders.length}
      ]
    },
    ordersList() {
      const list = this.orders
      /*
      return this.orders.slice().sort((a,b) => {
        return a.created_at - b.created_at
      })*/
      return list
    }
  },

  watch: {
    order: {
      handler: function(val) {
        if (typeof val.name === 'undefined') {
          this.unlocknav()
        } else {
          if (val.name == null && val.first_name == null && val.last_name == null && val.email == null) {
            this.unlocknav()
          } else {
            this.locknav()
          }
          
        }
        
      },
      deep: true
    }
  },

  apollo: {
    orders() {
      return {
        query: ORDERS_QUERY,
        variables: {
          status: 'ADDED'
        }
      }
    }
  },

  mounted() {
    // this.$refs.order_name.focus()
  },

  methods: {
    reset () {
      this.unlocknav()
      this.order = Object.assign(this.order, {
        name: null,
        first_name: null,
        last_name: null,
        email: null,
        note: null
      })
      this.$refs.form.reset()
      this.$refs.order_name.focus()
      this.unlocknav()
    },
    add() {
      this.locknav()
      if (!this.isValid) return
      if (this.isSaving) return
      this.isSaving = true
      const order = this.order
      this.$apollo.mutate({mutation: CREATE_ORDER_MUTATION, variables: {...order}})
        .then((response) => {
          this.unlocknav()
          this.isSaving = false
          const order = response.data.createOrder
          this.orders.unshift(order)
          this.reset()
          console.log(response)
          this.$store.dispatch('ADDED_ORDERS_COUNT', this.$apolloProvider)
        })
        .catch((error) => {
          this.isSaving = false
          console.error(error)
        })
    },
    deleteOrder(order)
    {
      this.locknav()
      this.isDeletingItem = order
      console.log(this.orders)
      let index = this.orders.indexOf(order)
      this.orders.splice(index, 1)
      this.$apollo.mutate({mutation: DELETE_ORDER_MUTATION, variables: {id: order.id}})
        .then((response) => {
          this.unlocknav()
          this.$store.dispatch('ADDED_ORDERS_COUNT', this.$apolloProvider)
          this.isDeletingItem = null
          //this.$store.dispatch('OPEN_ORDERS_COUNT', this.$apolloProvider)
          //const order = response.data.createOrder
          //this.orders.push(order)
          //this.reset()
          console.log(response)
        })
        .catch((error) => {
          this.isDeletingItem = null
          console.error(error)
        })
    },
    onProcess() {
      this.locknav()
      this.isLoading = true
      const ids = this.orders.map((o) => {
        return o.id
      })
      this.$apollo.mutate({mutation: PROCESS_ADDED_ORDERS_MUTATION, variables: {ids}})
        .then(() => {
          this.orders = []
          this.$apollo.queries.orders.refresh()
          this.$apollo.queries.orders.refetch()
          this.unlocknav()
          this.isLoading = false
          this.$store.dispatch('OPEN_ORDERS_COUNT', this.$apolloProvider)
          const _orders = ids.length > 1 ? 'orders' : 'order'
          this.$refs.feedback.success(`${ids.length} ${_orders} processed!`);
          // this.orders = []
          this.$refs.order_name.focus()
          this.$store.dispatch('ADDED_ORDERS_COUNT', this.$apolloProvider)
        })
        .catch(() => {
          this.isLoading = false
          this.$refs.feedback.error('an error occured while processing the orders.')
        })
    }
  }
}
</script>
