var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('AppBar',{attrs:{"title":"Completed Orders"}}),_c('v-container',[_c('Loading',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{ref:"search",attrs:{"label":"Search","solo":"","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.ordersList,"item-key":"id","loading":_vm.$apollo.loading,"loading-text":"Loading... Please wait","search":_vm.search,"sort-by":"pickedup_at","sort-desc":true,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.pickup_first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pickup_first_name)+" "+_vm._s(item.pickup_last_name)+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm._f("truncate")(item.note,20, '...')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.note))])])]}}],null,true),model:{value:(_vm.selectedOrders),callback:function ($$v) {_vm.selectedOrders=$$v},expression:"selectedOrders"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }