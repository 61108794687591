<template>
  <v-main>
    <AppBar title="Profile" :buttons="buttons" @onSave="onSave"></AppBar>
    <v-container>
      <Loading v-model="isLoading"></Loading>
      <Feedback ref="feedback"></Feedback>
      <v-form v-model="valid">
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-card>
              <v-text-field
                v-model="password"
                label="New Password"
                filled
                background-color="#fff"
                hide-details="auto"
                type="password"
                autocomplete="new-password"
              ></v-text-field>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-card>
              <v-text-field
                v-model="password1"
                label="Repeat Password"
                filled
                background-color="#fff"
                hide-details="auto"
                :rules="[rules.match]"
                type="password"
                autocomplete="new-password"
              ></v-text-field>
            </v-card>
          </v-col>
        </v-row>

        <h3 class="mt-8 mb-6 text-left">Email Notification</h3>
        <v-row>
          <v-col cols="12" sm="12">

              <v-checkbox
                v-model="user.order_received"
                label="Order Received"
                class="mt-0 mb-0"
                hide-details
              ></v-checkbox>              

          </v-col>
          <v-col cols="12" sm="12">

              <v-checkbox
                v-model="user.order_pickup"
                label="Customer Pickup"
                class="mt-0 mb-0"
                hide-details
              ></v-checkbox>              

          </v-col>
        </v-row>

      </v-form>
    </v-container>
  </v-main>
</template>
<script>
import { mapState } from 'vuex'
import AppBar from '../components/AppBar'
import Feedback from '../components/Feedback'
import Loading from '../components/Loading'
import {UPDATE_VENDOR_PROFILE} from '../graphql/mutations'
export default {
  components: {
    AppBar,
    Feedback,
    Loading
  },

  data:() => ({
    valid: false,
    password: null,
    password1: null,
    isLoading: false
  }),

  computed: {
    ...mapState(['user']),
    buttons() {
      return [
        {label: 'Save', color: 'error', click: 'onSave', disabled: !this.valid}
      ]
    },
    rules() {
      return {
        required: v => !!v || 'Required',
        match: () => this.password == this.password1 || 'Password does not match',
      }
    }
  },

  watch: {
    password() {
      this.locknav()
    },
    password1() {
      this.locknav()
    }
  },

  methods: {
    onSave() {
      this.locknav()
      const input = {
        password: this.password,
        order_received: this.user.order_received,
        order_pickup: this.user.order_pickup
      }
      this.$apollo.mutate({
        mutation: UPDATE_VENDOR_PROFILE,
        variables: {input}
      })
      .then(() => {
        this.unlocknav()
        this.isLoading = false
        this.$refs.feedback.success('Changes Saved.')
      })
      .catch(() => {
        this.isLoading = false
        this.$refs.feedback.error('An error occured.')
      })
    },
  },

}
</script>